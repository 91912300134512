import router from './router'
// import store from './store'
// const qs = require('qs')
import { getToken } from '@/utils/auth'
const whiteList = [
  '/login',
  '/userAgreement',
  '/privacyAgreement',
  '/install',
  '/installSuccess',
  '/home',
  '/instruction'
] // 白名单
const redirect_uri = encodeURIComponent(process.env.VUE_APP_REDIRECT_URL)
const appid = process.env.VUE_APP_ID
let authUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
// // 解决重复登录url添加重复的code与state问题
// function delCode() {
//   const url = window.location.href
//   // 解决重复登录url添加重复的code与state问题
//   const parseUrl = qs.parse(url.split('?')[1])
//   let loginUrl
//   if (parseUrl.code && parseUrl.state) {
//     delete parseUrl.code
//     delete parseUrl.state
//     loginUrl = `${url.split('?')[0]}?${qs.stringify(parseUrl)}`
//   } else {
//     loginUrl = url
//   }
//   window.location.replace(loginUrl)
// }

router.beforeEach(async (to, from, next) => {
  // const code = getParamsNew('code')
  // const openId = localStorage.getItem('openId')
  const hasToken = getToken()
  if (hasToken) {
    // 已登录
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      next()
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      // next(`/login?redirect=${to.path}`)
      window.location.replace(authUrl)
    }
  }
})
