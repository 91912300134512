import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from './utils/registerVantComponents'
import dayjs from 'dayjs'
import 'normalize.css/normalize.css' // 统一CSS样式
import './permission'
import VueClipboard from 'vue-clipboard2' // 复制数据
Vue.prototype.$dayjs = dayjs
Vue.use(VueClipboard)

Vue.use(Vant)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
