import Vue from 'vue'
import Vuex from 'vuex'
import { wxUserInfo, bind, userInfo } from '@/api/user.js'
import { getToken, removeToken, setToken } from '@/utils/auth'

Vue.use(Vuex)
const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: JSON.parse(localStorage.getItem('userInfo')) || {}
  }
}
export default new Vuex.Store({
  state: getDefaultState(),

  mutations: {
    SET_USER: (state, userInfo) => {
      state.userInfo = userInfo
      localStorage.setItem('userInfo', JSON.stringify(userInfo))
    },
    RESET_STATE: (state) => {
      Object.assign(state, getDefaultState())
    },
    SET_OPENID: (state, id) => {
      localStorage.setItem('openId', id)
    }
  },

  actions: {
    // code 获取用户信息 openid
    getInfo({ commit }, params) {
      return new Promise((resolve, reject) => {
        wxUserInfo(params)
          .then((response) => {
            if (response.status === 200) {
              const { data } = response

              if (!data) {
                return reject('请重新登录.')
              }
              let openId = data.openId
              commit('SET_USER', data)
              commit('SET_OPENID', openId)
              resolve(data)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 获取用户信息
    getUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        userInfo()
          .then((response) => {
            const { data } = response
            if (data) {
              commit('SET_USER', data)
              resolve(data)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    login({ commit }, params) {
      return new Promise((resolve, reject) => {
        bind(params)
          .then((res) => {
            if (res.status === 200) {
              if (res.data) {
                commit('SET_USER', res.data)
                setToken(res.data.userToken)
              }
              resolve(res)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // remove token
    resetToken({ commit }) {
      return new Promise((resolve) => {
        removeToken() // must remove  token  first
        localStorage.removeItem('userInfo')
        commit('RESET_STATE')
        resolve()
      })
    }
  },
  modules: {}
})
