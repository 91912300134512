import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/rechargeAgreement',
    name: 'rechargeAgreement',
    component: () => import('@/components/Agreements/RechargeAgreement.vue'),
    meta: {
      title: '购买协议', //
      requireAuth: false,
      keepAlive: false
    }
  },
  {
    path: '/instruction',
    name: 'instruction',
    component: () => import('@/views/instruction/index.vue'),
    meta: {
      title: '使用介绍', //
      requireAuth: true,
      keepAlive: false
    }
  },
  {
    path: '/timeRecord',
    name: 'timeRecord',
    component: () => import('@/views/timeRecord/index.vue'),
    meta: {
      title: '时长详情',
      requireAuth: true,
      keepAlive: false
    }
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import('@/views/recharge/index.vue'),
    meta: {
      title: '购买',
      requireAuth: true
    }
  },
  {
    path: '/',
    component: () => import('@/views/layout/index.vue'),
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('@/views/home/index.vue'),
        meta: {
          title: '首页',
          keepAlive: false,
          requireAuth: true
        }
      }
    ]
  },
  {
    path: '/user',
    component: () => import('@/views/layout/index'),
    redirect: 'user/index',
    children: [
      {
        path: '/user/index',
        name: 'User',
        component: () => import('@/views/user/index.vue'),
        meta: {
          title: '我的',
          requireAuth: true
        }
      }
    ]
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      title: '登录',
      requireAuth: true
    }
  },
  {
    name: 'userAgreement',
    path: '/userAgreement',
    component: () => import('@/components/Agreements/userAgreement.vue'),
    meta: {
      title: '全游云电脑用户服务协议',
      requireAuth: false
    }
  },
  {
    name: 'privacyAgreement',
    path: '/privacyAgreement',
    component: () => import('@/components/Agreements/privacyAgreement.vue'),
    meta: {
      title: '用户隐私协议',
      requireAuth: true
    }
  },
  {
    name: 'order',
    path: '/order',
    component: () => import('@/views/order/index.vue'),
    meta: {
      title: '购买记录',
      requireAuth: true
    }
  },
  {
    path: '/setting',
    component: () => import('@/views/setting/index.vue'),
    name: 'Setting',
    meta: {
      title: '设置',
      requireAuth: true
    }
  },
  {
    path: '/realName',
    component: () => import('@/views/setting/realName.vue'),
    name: 'RealName',
    meta: {
      title: '实名认证',
      requireAuth: true
    }
  },
  {
    path: '/bindPhone',
    component: () => import('@/views/setting/bindPhone.vue'),
    name: 'BindPhone',
    meta: {
      title: '绑定手机',
      requireAuth: true
    }
  },
  {
    path: '/exchange',
    name: 'exchange',
    component: () => import('@/views/exchange/index.vue'),
    meta: {
      title: '兑换',
      requireAuth: true
    }
  },
  {
    path: '/exchangeRecord',
    name: 'exchangeRecord',
    component: () => import('@/views/exchange/records.vue'),
    meta: {
      title: '兑换记录',
      requireAuth: true
    }
  },
  {
    path: '/remianTime',
    component: () => import('@/views/remianTimeDetail/index.vue'),
    name: 'RemianTime',
    meta: {
      title: '时长详情', //剩余时长详情
      requireAuth: true
    }
  },
  {
    path: '/install',
    name: 'install',
    component: () => import('@/views/install/index.vue'),
    meta: {
      title: '预约安装登记',
      requireAuth: false
    }
  },
  {
    path: '/installSuccess',
    name: 'installSuccess',
    component: () => import('@/views/install/success.vue'),
    meta: {
      title: '预约安装登记',
      requireAuth: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
