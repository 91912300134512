import Cookies from 'js-cookie'

const TokenKey = 'ZMY-Authorization'

export function getToken() {
  if (Cookies.get(TokenKey) === 'null') {
    return null
  } else {
    return Cookies.get(TokenKey)
  }
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
