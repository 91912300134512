import request from './axios'

export function login(data) {
  return request({
    url: '/api/agentUser/login',
    method: 'post',
    data
  })
}
// 用户信息
export function userInfo() {
  return request({
    url: '/api/timeUser/account',
    method: 'get'
  })
}

// code 获取微信用户信息
export function wxUserInfo(params) {
  return request({
    url: '/api/wx/userInfo',
    method: 'get',
    params
  })
}

// 实名认证
export function realName(data) {
  return request({
    url: '/api/account/realName',
    method: 'put',
    data
  })
}

// 短信验证码
export function mobileCode(params) {
  return request({
    url: '/api/auth/mobile/code',
    method: 'get',
    params
  })
}

// 绑定手机号
export function bind(data) {
  return request({
    url: '/api/wx/bindMobile',
    method: 'post',
    data
  })
}

// 用户剩余时长列表
export function remianTimeList(params) {
  return request({
    url: '/api/timeUser/remainTime',
    method: 'get',
    params
  })
}

// 省
export function province() {
  return request({
    url: '/api/region/province',
    method: 'get'
  })
}

// 市
export function city(provinceId) {
  return request({
    url: `/api/region/city/${provinceId}`,
    method: 'get'
  })
}

// 区
export function district(cityId) {
  return request({
    url: `/api/region/district/${cityId}`,
    method: 'get'
  })
}

// 安装申请
export function installApply(data) {
  return request({
    url: `/api/wx/subscribe-install/booking`,
    method: 'post',
    data
  })
}

// 校验当前账号使用手机号
export function verifyPhoneNo(data) {
  return request({
    url: `/api/user/verifyPhoneNo`,
    method: 'post',
    data
  })
}

// 换绑手机号
export function changeBindMobile(data) {
  return request({
    url: `/api/user/changeBindMobile`,
    method: 'post',
    data
  })
}
