//按需引入vant-ui组件
import {
  Button,
  List,
  Tabbar,
  TabbarItem,
  Field,
  Popup,
  Image,
  Icon,
  NavBar,
  Toast,
  cell,
  Tab,
  Tabs,
  Checkbox,
  CheckboxGroup,
  DatetimePicker,
  Empty,
  Col,
  Row,
  CellGroup,
  Cascader,
  Form,
  NoticeBar
} from 'vant'
const compontent = [
  Button,
  List,
  Tabbar,
  TabbarItem,
  Field,
  Popup,
  Image,
  Icon,
  NavBar,
  Toast,
  cell,
  Tab,
  Tabs,
  Checkbox,
  CheckboxGroup,
  DatetimePicker,
  Empty,
  Col,
  Row,
  CellGroup,
  Cascader,
  Form,
  NoticeBar
]

export default function install(Vue) {
  if (install.installed) return
  compontent.forEach((i) => Vue.component(i.name, i))
}
